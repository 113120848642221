import React, { useState, useEffect, useContext } from 'react';
import { RiUserAddLine } from 'react-icons/ri';
import { useForm , useFormContext , Controller} from 'react-hook-form';
import { CgCheckO } from 'react-icons/cg';
import { RiErrorWarningFill } from 'react-icons/ri';
import moment from 'moment';
import {
	Button,
	Box,
	Flex,
	FormControl,
	FormLabel,
	Input,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	useToast,
	IconButton,
	FormHelperText,
	Text,
	Select,
	Textarea,
	Radio, RadioGroup, Stack
} from '@chakra-ui/core';
import context from 'context/dataProvider';

const ChurchMassTimeForm = (props) => {
	const {setData, masstypedata, languagedata} = useContext(context);
	const targetID = props.targetID ;
	const fieldName = `masstime[${props.number}]`;
	const {register, handleSubmit, errors , control} = useFormContext();;
	const toast = useToast();
	const [formData, setFormData] = useState({
		name: null,
	});
	return (
		<Box>
				{[
						{zh: '彌撒種類', en: 'type_id'},
						{zh: '彌撒語系', en: 'languages_id'},
						{zh: '頻率', en: 'week'},
						{zh: '星期', en: 'day'},
						{zh: '彌撒開始時間', en: 'start_time'},
						{zh: '彌撒結束時間', en: 'end_time'},
						{zh: '彌撒備註', en: 'notes'},
						{zh: '教堂', en: 'church_id'},
					].map((item, i) => {
						switch (item.en) {
							case 'week':
								return (
									<FormControl key={i}>
										<FormLabel htmlFor={item.en} mb={1} mt={1}>
											{item.zh}
										</FormLabel>
										<RadioGroup name={`${fieldName}.${item.en}`} isInline>
											<Radio variantColor="brand" value="每週" ref={register({ required: true })}>
												每週
											</Radio>
											<Radio variantColor="brand" value="1" ref={register({ required: true })}>
												其他
											</Radio>
										</RadioGroup>
										{errors.en && (
											<FormHelperText>請填入{item.zh}</FormHelperText>
										)}
									</FormControl>
								);
							case 'day':
								return (
									<FormControl key={i}>
										<FormLabel htmlFor={item.en} mb={1} mt={1}>
											{item.zh}
										</FormLabel>
										<Select placeholder="選擇區間" ref={register({ required: true})} name={`${fieldName}.${item.en}`}
												id={item.en}
												value={formData[item.en]}
										>
											<option value="0">星期日</option>
											<option value="1">星期一</option>
											<option value="2">星期二</option>
											<option value="3">星期三</option>
											<option value="4">星期四</option>
											<option value="5">星期五</option>
											<option value="6">星期六</option>
										</Select>
										{errors.en && (
											<FormHelperText>請填入{item.zh}</FormHelperText>
										)}
									</FormControl>
								);
							case 'start_time':
								return (
									<FormControl key={i}>
										<FormLabel htmlFor={item.en} mb={1} mt={1}>
											{item.zh}
										</FormLabel>
										<Input
											type="time"
											id={item.en}
											name={`${fieldName}.${item.en}`}
											ref={register({ required: true})}
										/>
										{errors.en && (
											<FormHelperText>請填入{item.zh}</FormHelperText>
										)}
									</FormControl>
								);
							case 'end_time':
								return (
									<FormControl key={i}>
										<FormLabel htmlFor={item.en} mb={1} mt={1}>
											{item.zh}
										</FormLabel>
										<Input
											type="time"
											id={item.en}
											name={`${fieldName}.${item.en}`}
											ref={register({ required: true})}
										/>
										{errors.en && (
											<FormHelperText>請填入{item.zh}</FormHelperText>
										)}
									</FormControl>
								);
							case 'type_id':
								return (
									<FormControl key={i}>
										<FormLabel htmlFor={item.en} mb={1} mt={1}>
											{item.zh}
										</FormLabel>
										<Select placeholder="選擇彌撒種類" ref={register({ required: true})} name={`${fieldName}.${item.en}`}
												id={item.en}
												value={formData[item.en]}
										>
											{masstypedata !== undefined ? masstypedata.map((value, index) => (
												<option key={index} value={value.id}>
													{value.name}
												</option>
											)) : null}
										</Select>
										{errors.en && (
											<FormHelperText>請填入{item.zh}</FormHelperText>
										)}
									</FormControl>
								);
							case 'languages_id':
								return (
									<FormControl key={i}>
										<FormLabel htmlFor={item.en} mb={1} mt={1}>
											{item.zh}
										</FormLabel>
										<Select placeholder="選擇彌撒語系" ref={register({ required: true})} name={`${fieldName}.${item.en}`}
												id={item.en}
												value={formData[item.en]}
										>
											{languagedata !== undefined ? languagedata.map((value, index) => (
												<option key={index} value={value.id}>
													{value.name}
												</option>
											)) : null}
										</Select>
										{errors.en && (
											<FormHelperText>請填入{item.zh}</FormHelperText>
										)}
									</FormControl>
								);
							case 'notes':
								return (
									<FormControl key={i}>
										<FormLabel htmlFor={item.en} mb={1} mt={1}>
											{item.zh}
										</FormLabel>
										<Textarea placeholder="彌撒備註" name={`${fieldName}.${item.en}`} ref={register()}/>
										{errors.en && (
											<FormHelperText>請填入{item.zh}</FormHelperText>
										)}
									</FormControl>
								);
							default:
								return (
									<FormControl key={i}>
										<Input
											type="hidden"
											id={"church_id"}
											name={`${fieldName}.${item.en}`}
											ref={register({ required: true})}
											defaultValue={targetID}
										/>
										{errors.en && (
											<FormHelperText>請填入{item.zh}</FormHelperText>
										)}
									</FormControl>
								);
						}
					})}
		</Box>
	);
};

export default ChurchMassTimeForm;

//moment(new Date()).format('YYYYMMDD')
