import React, { useState } from 'react'
import { useAsyncDebounce } from 'react-table'
import { AiOutlineSearch } from 'react-icons/ai'
import { Box, Input } from '@chakra-ui/core'

export const GlobalFilter = ({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) => {
	const count = preGlobalFilteredRows.length
	const [value, setValue] = useState(globalFilter)

	const onChange = useAsyncDebounce((value) => {
		setGlobalFilter(value || undefined)
	}, 200)

	return (
		<Box
			alignItems="center"
			bg="white"
			borderBottom="2px solid #eeeeee"
			borderRadius="10px 10px 0 0"
			display="flex"
			p={2}
			position="relative"
		>
			<Input
				border="1px solid #eeeeee"
				placeholder={`共有 ${count} 筆記錄`}
				type="keywords"
				value={value || ''}
				onChange={(e) => {
					setValue(e.target.value)
					onChange(e.target.value)
				}}
			/>
			<Box as={AiOutlineSearch} bg="transparent" color="gray.300" p="10px" position="absolute" right={2} size="40px" />
		</Box>
	)
}
