import React, { useState, useEffect, useContext } from 'react';
import { RiUserAddLine } from 'react-icons/ri';
import { FaSave } from 'react-icons/fa';
import { useForm } from 'react-hook-form';
import { IoMdClose } from 'react-icons/io';
import moment from 'moment';
import {
	Button,
	Box,
	Flex,
	FormControl,
	FormLabel,
	Input,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	useToast,
	IconButton,
	FormHelperText,
	Text,
	Select
} from '@chakra-ui/core';
import { getonlyMassList,insertonlyMassList,updateonlyMassList,getMassList } from 'api/MassType';
import context from 'context/dataProvider';

const userGetList = [
	'name',
];
const userGetList_TC = [
	'彌薩種類名稱',
];

const userGetListArray = [];

for (let itms = 0; itms <= userGetList_TC.length - 1; itms++) {
	userGetListArray.push({
		dataName: userGetList[itms],
		title: userGetList_TC[itms],
	});
}

const EditMassType = (props) => {
	const { setData } = useContext(context);
	const targetID = props.targetID || null;
	const { register, handleSubmit, errors } = useForm();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const toast = useToast();
	const [formData, setFormData] = useState({
		name: null,
	});
	const [scrollBehavior, setScrollBehavior] = useState('inside');

	const fetchList = async () => {
		const { data } = await getMassList();

		setData(data.data);
	};

	const onSubmit = (values) => {
		if (targetID) {
			console.log(values)
			updateonlyMassList(targetID, values)
				.then(function (res) {
					if (res.status === 200) {
						toast({
							duration: 2000,
							position: 'bottom',
							render: (props) => (
								<Box
									fontSize="2em"
									p="0.5em 1.5em"
									color="white"
									background="#38a169"
									borderRadius="10px"
									m={5}
								>
									{values.name}
									<Text>資料更新完成</Text>
								</Box>
							),
						});
						onClose();
						fetchList();
					}
				})
				.catch(function (error) {
					console.log(error.response);
				});
		} else {
			console.log(values)
			insertonlyMassList(values)
				.then(function (res) {
					console.log(res)
					if (res.status === 200) {
						toast({
							duration: 2000,
							position: 'bottom',
							render: (props) => (
								<Box
									fontSize="2em"
									p="0.5em 1.5em"
									color="white"
									background="#38a169"
									borderRadius="10px"
									m={5}
								>
									{values.name}
									<Text>資料建立完成</Text>
								</Box>
							),
						});
						onClose();
						fetchList();
					}
				})
				.catch(function (error) {
					toast({
						duration: 2000,
						position: 'bottom',
						render: (props) => (
							<Box
								fontSize="2em"
								p="0.5em 1.5em"
								color="white"
								background="#e53e3e"
								borderRadius="10px"
								m={5}
							>
								<Text>網路連線異常</Text>
							</Box>
						),
					});
				});
		}
	};
	const timesecond = (values) => {
		if(values!== undefined) {
			const strAry = values.split(':');
			return strAry[0] + ":" + strAry[1];
		}
		else{
			return null
		}
	};
	useEffect(() => {
		if (isOpen && targetID) {
			async function fetchData() {
				const { data } = await getonlyMassList(targetID);
				setFormData(data.data);
			}

			fetchData();
		} else {
			setFormData({ name: null });
		}
	}, [isOpen, targetID]);

	return (
		<Flex display={targetID ? 'inline' : 'flex'}>
			{targetID ? (
				<IconButton onClick={onOpen} icon="edit" />
			) : (
				<Button
					onClick={onOpen}
					ml="auto"
					mb={4}
					backgroundColor="brand.400"
					_hover={{ bg: 'brand.500' }}
					_active={{ bg: 'brand.500' }}
					color="rgba(121, 58, 0, 0.8)"
					variant="solid"
				>
					<Box as={RiUserAddLine} mr={2} />
					新增彌撒種類
				</Button>
			)}
			<Modal
				isOpen={isOpen}
				onClose={onClose}
				isCentered
				scrollBehavior={targetID ? scrollBehavior : null}
			>
				<ModalOverlay />
				<ModalContent borderRadius="10px" height="auto">
					<ModalHeader>{targetID ? '修改' : '新增'}彌撒種類</ModalHeader>
					<ModalCloseButton />
					<ModalBody mb={3}>
						<form onSubmit={handleSubmit(onSubmit)}>
							{targetID
								? userGetListArray.map((item, i) => {
									switch (item.dataName) {
										case 'name':
											return (
												<FormControl key={i}>
													<FormLabel htmlFor={item.dataName} mb={1} mt={1}>
														{item.title}
													</FormLabel>
													<Input
														type="text"
														id={item.dataName}
														name={item.dataName}
														ref={register}
														defaultValue={formData[item.dataName]}
													/>
													{errors.name && (
														<FormHelperText>請填入{item.title}</FormHelperText>
													)}
												</FormControl>
											);
										default:
											return (
												<FormControl key={i}>
													<Input
														type="hidden"
														id={"church_id"}
														name={"church_id"}
														ref={register}
														defaultValue={localStorage.getItem('cid')}
													/>
													{errors.name && (
														<FormHelperText>請填入{item.title}</FormHelperText>
													)}
												</FormControl>
											);
									}
								})
								: [
									{ zh: '彌撒種類名稱', en: 'name' },
								].map((item, i) => {
										switch (item.en) {
										case 'name':
											return (
												<FormControl key={i}>
													<FormLabel htmlFor={item.en} mb={1} mt={1}>
														{item.zh}
													</FormLabel>
													<Input
														type="text"
														id={item.en}
														name={item.en}
														ref={register({
															required: true,
														})}
													/>
													{errors[item.en] && (
														<FormHelperText>請填入{item.zh}</FormHelperText>
													)}
												</FormControl>
											);
										default:
											return (
												<FormControl key={i}>
													<Input
														type="hidden"
														id={"church_id"}
														name={"church_id"}
														ref={register({
															required: true,
														})}
														defaultValue={localStorage.getItem('cid')}
													/>
													{errors[item.en] && (
														<FormHelperText>請填入{item.zh}</FormHelperText>
													)}
												</FormControl>
											);
										}
								})}

							<Flex mt={4} pb={2} justify="flex-end">
								<Button variant="ghost" onClick={onClose} mr={3}>
									<Box as={IoMdClose} mr={2} />
									取消
								</Button>
								<Button
									backgroundColor="brand.400"
									_hover={{ bg: 'brand.500' }}
									_active={{ bg: 'brand.500' }}
									color="white"
									type="submit"
								>
									<Box as={FaSave} mr={2} />
									{targetID ? '修改' : '新增'}
								</Button>
							</Flex>
						</form>
					</ModalBody>
				</ModalContent>
			</Modal>
		</Flex>
	);
};

export default EditMassType;

//moment(new Date()).format('YYYYMMDD')
