import React from 'react';
import styled from 'styled-components';
import { TableContent } from 'shared/Table/TableContent';

const StyledTable = styled.div`
  display: block;
  max-width: 100%;

  .table {
    background: white;
    border-radius: 0 0 10px 10px;

    .tr {
      min-width: 100%;
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .th,
    .td {
      background: white;
      border-bottom: 2px solid #eeeeee;
      border-right: 2px solid #eeeeee;
      margin: 0;
      overflow: hidden;
      padding: 0.5rem;

      :last-child {
        border-right: 0;
      }

      :not([data-sticky-td]) {
        flex-grow: 1;
      }

      span {
        display: inline-flex;
        margin-left: 0.5em;
      }

      .resizer {
        display: inline-block;
        width: 5px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;

        &.isResizing {
          background: red;
        }
      }
    }
    @media (max-width: 768px) {
      .header .th {
        display: none !important;
      }

      .tr,
      .th {
        display: block !important;
        width: 100% !important;
      }

      .body .tr {
        float: left !important;
      }

      .body .td {
        display: flex !important;
        justify-content: space-between;
        width: 100% !important;
        align-items: center;
        padding-left: 16px;
        padding-right: 16px;
        border-right: none;

        &:before {
          content: attr(data-th);
        }

        &:last-child {
          border-bottom: 2px solid;
        }
      }
    }

    &.sticky {
      overflow: auto;
      .header,
      .footer {
        position: sticky;
        z-index: 1;
        width: fit-content;
        min-width: 100%;
      }

      .header {
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
        top: 0;
      }

      .footer {
        bottom: 0;
      }

      .body {
        max-height: 68vh;
        position: relative;
        z-index: 0;
      }

      [data-sticky-td] {
        position: sticky;
      }

      [data-sticky-last-left-td] {
        box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.05);
      }

      [data-sticky-first-right-td] {
        box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.05);
      }
    }
  }
`;
const Table = (props) => {
    const targetID = props.Church || null;
  return (
    <StyledTable>
      <TableContent formRegister={props.formRegister} Church={targetID}/>
    </StyledTable>
  );
};

export default Table;

// table padding 原本是1rem

/*
    @media (max-width: 768px) {
      .header .th {
        :not(:first-child) {
          display: none !important;
        }
      }
      .td,
      .th {
        display: block;
        clear: both;
      }
      .td[data-th]:before {
        content: attr(data-th);
        float: left;
        border: 1px solid red;
      }
    }
    
    */
