import React, { useEffect, useState } from 'react';
import Table from 'shared/Table/Table';
import { Provider } from 'context/dataProvider';
import { getLanguageList } from 'api/Language';
import { getMassList } from 'api/MassType';
import { getchurchmasstime } from 'api/ChurchMassTime';
import { RiAddLine } from 'react-icons/ri'
import { Box, Button, IconButton ,Flex} from "@chakra-ui/core";
import { Link ,useParams} from "react-router-dom";
import Axios from 'axios'

export const ChurchMassTime = (props) => {
	const [data, setData] = useState([]);
	const [masstypedata, setmasstypedata] = useState([]);
	const [languagedata, setlanguagedata] = useState([]);
	const {targetID} = useParams()
	const [type] = useState('ChurchMassTime');
	const [columns] = useState([
		{
			Header: 'No',
			accessor: 'id',
		},
		{
			Header: '彌撒種類',
			accessor: 'type_id',
		},
		{
			Header: '彌撒時間',
			accessor: 'start_time',
		},
		{ Header: '操作', accessor: 'masstimefunction' },
	]);

	const fetchData = () => {
		Axios.all([getMassList(),getLanguageList(),getchurchmasstime(targetID)])
			.then(Axios.spread((acct,languagedetail,churchmasstimedetail) => {
				// 請求都完成後
				if (acct.status === 200 && languagedetail.status ===200 && churchmasstimedetail.status ===200) {
					console.log(churchmasstimedetail)
					setData(churchmasstimedetail.data.data);
					setmasstypedata(acct.data.data);
					setlanguagedata(languagedetail.data.data);
				}
			}))
			.catch((error) => {
				console.error(error.response)
			});
	};

	useEffect(() => {
		fetchData();
	}, []);

	const context = {
		data,
		masstypedata,
		languagedata,
		setData,
		columns,
		type,
	};

	return (
		<Provider value={context}>
			<Flex flexDirection="flex-end">
			<Button
				as={Link}
				to={'/dashboard/churchlist/add/' + targetID}
				ml="auto"
				mb={4}
				backgroundColor="brand.400"
				_hover={{ bg: 'brand.500' }}
				_active={{ bg: 'brand.500' }}
				color="rgba(121, 58, 0, 0.8)"
				variant="solid"
			>
				<Box as={RiAddLine} mr={2} />
				新增彌薩時間
			</Button>
			</Flex>
			<Table Church={targetID}/>
		</Provider>
	);
};
