import Axios from 'axios';
import { getToken } from 'utils/auth'
export const getLanguageList = () => {
	const { REACT_APP_API_URL } = process.env;
	let config = {
		headers: {
			Authorization: `Bearer ${getToken()}`,
			'Content-Type': `application/json`,
		},
	};

	return Axios.get(REACT_APP_API_URL + '/languages', config);
};
export const getonlyLanguageList = (id) => {
	const { REACT_APP_API_URL } = process.env;
	let config = {
		headers: {
			Authorization: `Bearer ${getToken()}`,
			'Content-Type': `application/json`,
		},
	};

	return Axios.get(REACT_APP_API_URL + '/languages/' + id, config);
};
export const insertonlyLanguageList = (data) => {
	const { REACT_APP_API_URL } = process.env;
	let config = {
		headers: {
			Authorization: `Bearer ${getToken()}`,
			'Content-Type': `application/json`,
		},
	};

	return Axios.post(REACT_APP_API_URL + '/languages', data, config);
};

export const updateonlyLanguageList = (id, data) => {
	const { REACT_APP_API_URL } = process.env;
	let config = {
		headers: {
			Authorization: `Bearer ${getToken()}`,
			'Content-Type': `application/json`,
		},
	};

	return Axios.put(REACT_APP_API_URL + '/languages/' + id, data, config);
};

export const deleteLanguage = (id, data) => {
	const { REACT_APP_API_URL } = process.env;
	let config = {
		headers: {
			Authorization: `Bearer ${getToken()}`,
			'Content-Type': `application/json`,
		},
	};

	return Axios.delete(REACT_APP_API_URL + '/languages/' + id, config);
};