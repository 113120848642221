import React, { useState, useEffect } from 'react';
import {
  HashRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';
import { ThemeProvider, CSSReset, useToast } from '@chakra-ui/core';
import { customTheme } from 'common/customTheme';
import { Provider as GlobalProvider } from 'context/globalContext';
import { connect } from 'react-redux';
import { login } from 'actions/user';
import Axios from 'axios';
import {
  userIsAuthenticatedRedir,
  userIsNotAuthenticatedRedir,
  userIsAdminRedir,
  // userIsAuthenticated,
  // userIsNotAuthenticated,
} from 'auth';
import { getToken } from 'utils/auth';
import { Dashboard } from 'layout/Dashboard/Dashboard';
import LoginPage from 'pages/LoginPage/LoginPage';
const Login = userIsNotAuthenticatedRedir(LoginPage);
const Admin = userIsAuthenticatedRedir(userIsAdminRedir(Dashboard));

const Container = (props) => {
  const { REACT_APP_API_URL } = process.env;
  const toast = useToast();
  const [windowSize, setWindowsSize] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const sidebarInit = windowSize.width > 640 ? true : false;
  const [sidebarOpen, toggleSidebarOpen] = useState(sidebarInit);
  const [userType, setUserType] = useState(0);
  const [userId, setUserId] = useState();
  const [userToken, setUserToken] = useState();
  const [auth, setAuth] = useState();
  const [cardData, setCardData] = useState();
  const [activeItem, setActiveItem] = useState();
  const [selectedCardData, setSelectedCardData] = useState();

  const globalContextValue = {
    windowSize,
    setWindowsSize,
    sidebarOpen,
    toggleSidebarOpen,
    userType,
    setUserType,
    userId,
    setUserId,
    userToken,
    setUserToken,
    auth,
    setAuth,
    cardData,
    setCardData,
    activeItem,
    setActiveItem,
    selectedCardData,
    setSelectedCardData,
  };

  useEffect(() => {
    console.log(
      `${process.env.REACT_APP_NAME} version: v${process.env.REACT_APP_VERSION}`
    );
  }, []);

  useEffect(() => {
    let config = {
      headers: { Authorization: `Bearer ${getToken()}` },
    }
    Axios.post(REACT_APP_API_URL + '/check', {}, config)
        .then(function (response) {
          console.log(response)
          if (response.status === 200) {
            props.login({
              token: getToken(),
              isAdmin: true,
            })
          }
        })
        .catch(function (error) {
        })

    const handleResize = () => {
      setWindowsSize({
        height: window.innerHeight,
        width: window.innerWidth,
      })
    }

    window.addEventListener('resize', handleResize)
  }, [REACT_APP_API_URL])

  useEffect(() => {
    let line = window.navigator.userAgent.indexOf('Line') > -1;
    let mobile = window.navigator.userAgent.indexOf('Mobile') > -1;
    // let android = window.navigator.userAgent.indexOf('Android') > -1;
    // let iphone = window.navigator.userAgent.indexOf('iPhone') > -1;
    if (mobile && line) {
      window.location = './?openExternalBrowser=1';
    }
    if (window.location.href.indexOf('openExternalBrowser=1') > -1) {
      window.location = window.location.origin;
    }
  }, []);

  return (
    <GlobalProvider value={globalContextValue}>
      <ThemeProvider theme={customTheme}>
        <CSSReset />
        <Router>
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/dashboard" component={Admin} user={props.user} />
            <Redirect to="/login" />
          </Switch>
        </Router>
      </ThemeProvider>
    </GlobalProvider>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, { login })(Container);

/*

 <Route path="/qrcode_time" component={PrintCardPage} />
            <Route path="/login" component={Login} />
            <Route path="/dashboard" component={Admin} user={props.user} />
            <Route exact path="/" component={Login} />
            <Redirect to="/login" />



*/
