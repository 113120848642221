import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ChurchPage } from 'pages/ChurchPage/ChurchPage';
import { ChurchMassTime } from 'pages/ChurchPage/ChurchMassTime';
import context from 'context/globalContext';
import { MassType } from 'pages/MassType/MassType';
import { LanguagePage } from 'pages/LanguagePage/Language';
import { AddChurchMassTime } from 'pages/ChurchPage/AddChurchMassTime';

const Router = () => {
  const { windowSize } = useContext(context);
  return (
    <Switch>
      <Route path="/dashboard/churchlist" exact component={ChurchPage} />
      <Route path="/dashboard/mass_type_setting" exact component={MassType} />
      <Route path="/dashboard/language_setting" exact component={LanguagePage} />
      <Route path="/dashboard/churchlist/edit/:targetID" exact component={ChurchMassTime} />
        <Route path="/dashboard/churchlist/add/:targetID" exact component={AddChurchMassTime} />
    </Switch>
  );
};

export default Router;
