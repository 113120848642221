import React, { useContext } from 'react';
import styled from 'styled-components';
import context from 'context/globalContext';
import { Box, Button, Text, Stack } from '@chakra-ui/core';
import { SidebarMenuItem } from './SidebarMenuItem';
import { logout } from 'actions/user';
import { connect } from 'react-redux';

const StyledSidebar = styled(Box)`
  height: 100vh;
  overflow: auto;
  position: fixed;
  width: 250px;
`;
const StyledLogoImg = styled.img`
  margin: auto;
`;
const Sidebar = (props) => {
  const { sidebarOpen, activeItem, windowSize } = useContext(context);

  const menuList = [
    {
      title: '彌撒時間設定',
      title_en: 'churchlist',
      icon: 'AiFillSetting',
      router: '/dashboard/churchlist',
    },
    {
      title: '彌撒種類設定',
      title_en: 'mass_type_setting',
      icon: 'AiFillSetting',
      router: '/dashboard/mass_type_setting',
    },
    {
      title: '語系設定',
      title_en: 'language_setting',
      icon: 'AiFillSetting',
      router: '/dashboard/language_setting',
    },
  ];

  return (
    <StyledSidebar
      bg="white"
      boxShadow="xl"
      zIndex={1}
      left={sidebarOpen ? '0' : '-250px'}
      transition="left .2s"
    >
      <Box mb={4} mt={4} textAlign="center">
        麥力後台
      </Box>
      {menuList.map((item, i) => {
        let menuItem = null;
        menuItem = (
          <SidebarMenuItem
            key={i}
            item={item}
            active={activeItem === item.title_en ? 'active' : null}
          />
        );
        // INFO: userType: 0 (guest); 1 (admin)
        /*   if (userType === 1) {
          menuItem = <SidebarMenuItem key={i} item={item} />;
        } else {
          if (item.admin === false) {
            menuItem = <SidebarMenuItem key={i} item={item} />;
          }
        }*/
        return menuItem;
      })}

      <Box
        p="1em 1.25em"
        fontWeight="normal"
        mt={5}
        d="flex"
        alignItems="center"
        justifyContent="center"
        borderTop=" 2px solid rgba(174, 126, 82, 0.2)"
        color="brand.400"
        flexWrap="wrap"
      >
        <Stack spacing={2}>
          <Text isTruncated textAlign="center" p="1em 0">
            {localStorage.getItem('userName')}，你好!
          </Text>
          <Button
            backgroundColor="white"
            fontWeight="normal"
            _hover={{ bg: 'white' }}
            _active={{ bg: 'white' }}
            color="brand.400"
            border="1px solid"
            p="5px 2.9375em"
            borderRadius="18px"
            h="auto"
            onClick={() => {
              props.logout();
            }}
          >
            登出
          </Button>
        </Stack>
      </Box>
    </StyledSidebar>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, { logout })(Sidebar);
