import React, { Fragment, useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import {
  useTable,
  useBlockLayout,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from 'react-table';
import { useSticky } from 'react-table-sticky';
import { AiOutlineQrcode } from 'react-icons/ai';
import { FaUserCheck } from 'react-icons/fa';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Flex,
  Icon,
  IconButton,
    Button,
  useToast,
  Checkbox,
} from '@chakra-ui/core';
import { deleteLanguage } from 'api/Language';
import { deleteMassType } from 'api/MassType';
import EditChurch from 'pages/ChurchPage/EditChurch';
import EditMassType from 'pages/MassType/EditMassType';
import EditLanguage from 'pages/LanguagePage/EditLanguage';
import context from 'context/dataProvider';
import { GlobalFilter } from './GlobalFilter';
import { Pagination } from './Pagination';
import { CgCheckO } from 'react-icons/cg';
import styled from 'styled-components';
import globalContext from 'context/globalContext';

const StyledPagination = styled(Box)``;

export const TableContent = (props) => {
  const { data, setData, columns, type, sortBy } = useContext(context);
  const Church = props.Church || null;
  const { windowSize } = useContext(globalContext);
  const [currentID, setCurrentID] = useState(0);
  const [currentName, setCurrentName] = useState(0);
  const [sortList, setSortList] = useState([]);
  const [alertIsOpen, setAlertIsOpen] = useState();
  const cancelRef = React.useRef();
  const toast = useToast();
  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 0,
      width: 140,
      maxWidth: 400,
    }),
    []
  );
  const onClose = () => setAlertIsOpen(false)
  const deleteTableData = (id) => {
    setAlertIsOpen(false);
    switch (type) {
      case 'Language':
        deleteLanguage(id)
          .then(function (res) {
            if (res.status === 200) {
              toast({
                duration: 2000,
                position: 'bottom',
                render: () => (
                  <Flex
                    background=" #00D086"
                    boxShadow=" 0px 4px 40px rgba(121, 58, 0, 0.2)"
                    borderRadius=" 10px"
                    w="270px"
                    h="150px"
                    maxW="95vw"
                    maxH="25vh"
                    alignItems="center"
                    justifyContent="center"
                    mb="1em"
                  >
                    <Flex color="white">
                      <CgCheckO size="30px" />
                      <Box ml="14.5px" mr="12px" textAlign="left">
                        <Box
                          fontSize="30px"
                          mb="12px"
                          fontWeight="600"
                          lineHeight="30px"
                        >
                          語系刪除成功
                        </Box>
                      </Box>
                    </Flex>
                  </Flex>
                ),
              });
            }
          })
          .catch(function (error) {
            console.log(error);
          });
        break;
      case 'MassType':
        deleteMassType(id)
            .then(function (res) {
              if (res.status === 200) {
                toast({
                  duration: 2000,
                  position: 'bottom',
                  render: () => (
                      <Flex
                          background=" #00D086"
                          boxShadow=" 0px 4px 40px rgba(121, 58, 0, 0.2)"
                          borderRadius=" 10px"
                          w="270px"
                          h="150px"
                          maxW="95vw"
                          maxH="25vh"
                          alignItems="center"
                          justifyContent="center"
                          mb="1em"
                      >
                        <Flex color="white">
                          <CgCheckO size="30px" />
                          <Box ml="14.5px" mr="12px" textAlign="left">
                            <Box
                                fontSize="30px"
                                mb="12px"
                                fontWeight="600"
                                lineHeight="30px"
                            >
                              彌薩種類刪除成功
                            </Box>
                          </Box>
                        </Flex>
                      </Flex>
                  ),
                });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        break;

      default:
        break;
    }
    const result = data.filter((item) => item.id !== id);
    setData(() => result);
  };

  useEffect(() => {
    if (sortBy) {
      setSortList(sortBy);
    }
  }, [sortBy]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    state: { pageIndex, pageSize },
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      disableSortRemove: true,
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        sortBy: [
          {
            id: type === 'Staff' ? 'id' : 'date',
            desc: type === 'Staff' ? false : true,
          },
        ],
      },
      defaultColumn,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useBlockLayout,
    useSticky
  );

  return (
    <Fragment>
      <AlertDialog isOpen={alertIsOpen} leastDestructiveRef={cancelRef} onClose={onClose} isCentered>
        <AlertDialogOverlay />
        <AlertDialogContent borderRadius="10px">
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            刪除
          </AlertDialogHeader>

          <AlertDialogBody>
            你確定要<b>刪除這筆資料</b>嗎？
            <br />
            此操作不能回復哦！
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              取消
            </Button>
            <Button variantColor="red" onClick={() => deleteTableData(currentID)} ml={3}>
              刪除
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={state.globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
      <Box overflowX="auto" mb={3}>
        <div {...getTableProps()} className="table sticky">
          <div className="header">
            {headerGroups.map((headerGroup) => (
              <div {...headerGroup.getHeaderGroupProps()} className="tr">
                {headerGroup.headers.map((column) => (
                  <div
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="th"
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <Icon name="triangle-down" size="10px" />
                        ) : (
                          <Icon name="triangle-up" size="10px" />
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div {...getTableBodyProps()} className="body">
            {data.length ? (
              page.map((row) => {
                prepareRow(row);
                return (
                  <div {...row.getRowProps()} className="tr">
                    {row.cells.map((cell) => (
                      <div
                        {...cell.getCellProps()}
                        data-th={cell.column.Header}
                        className="td"
                      >
                        {(() => {
                          switch (cell.column.id) {
                            case 'print':
                              return (
                                <Checkbox
                                  value={cell.row.original.id}
                                  name="id"
                                  ref={props.formRegister}
                                >
                                  會員{cell.row.original.id}
                                </Checkbox>
                              );
                            case 'function':
                              return (
                                  <IconButton
                                      as={Link}
                                      to={'/dashboard/churchlist/edit/' + cell.row.original.id}
                                      icon="edit"
                                  />
                              );
                            case 'no':
                              return parseInt(row.id) + 1;
                              case 'week':
                                  switch (row.values.week) {
                                      case 0:
                                          return "星期日";
                                      case 1:
                                          return "星期一";
                                      case 2:
                                          return "星期二";
                                      case 3:
                                          return "星期三";
                                      case 4:
                                          return "星期四";
                                      case 5:
                                          return "星期五";
                                      case 6:
                                          return "星期六";
                                  }
                              case 'massfunction':
                                  return (
                                      <Fragment>
                                  <EditMassType targetID={cell.row.values.id} />
                                        <IconButton
                                            ml={2}
                                            variant="ghost"
                                            variantColor="red"
                                            aria-label="刪除"
                                            fontSize="20px"
                                            icon="delete"
                                            id={cell.row.values.id}
                                            onClick={() => {
                                              setAlertIsOpen(true)
                                              setCurrentID(cell.row.original.id)
                                            }}
                                        />
                                      </Fragment>
                                  );
                            case 'languagefunction':
                              return (
                                  <Fragment>
                                    <EditLanguage targetID={cell.row.values.id} />
                                    <IconButton
                                        ml={2}
                                        variant="ghost"
                                        variantColor="red"
                                        aria-label="刪除"
                                        fontSize="20px"
                                        icon="delete"
                                        id={cell.row.values.id}
                                        onClick={() => {
                                          setAlertIsOpen(true)
                                          setCurrentID(cell.row.original.id)
                                        }}
                                    />
                                  </Fragment>
                              );
                            case 'masstimefunction':
                              return (
                                  <Fragment>
                                    <EditChurch targetID={cell.row.values.id} ChurchID={Church}/>
                                  </Fragment>
                              );
                            default:
                              return cell.render('Cell');
                          }
                        })()}
                      </div>
                    ))}
                  </div>
                );
              })
            ) : (
              <Box textAlign="center" p={6}>
                NO DATA
              </Box>
            )}
          </div>
        </div>
      </Box>
      <Flex
        justify={windowSize.width > 768 ? 'space-between' : 'center'}
        wrap="wrap"
      >
        <Box mb={3}>共有 {rows.length} 筆資料</Box>
        <Box>
          <Pagination
            gotoPage={gotoPage}
            previousPage={previousPage}
            nextPage={nextPage}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            pageCount={pageCount}
            pageOptions={pageOptions}
            pageIndex={pageIndex}
            pageSize={pageSize}
            setPageSize={setPageSize}
            width={windowSize.width}
          />
        </Box>
      </Flex>
    </Fragment>
  );
};
