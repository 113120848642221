import React, { useState, useEffect, useContext } from 'react';
import { RiUserAddLine } from 'react-icons/ri';
import { FaSave } from 'react-icons/fa';
import { useForm } from 'react-hook-form';
import { IoMdClose } from 'react-icons/io';
import { CgCheckO } from 'react-icons/cg';
import { RiErrorWarningFill } from 'react-icons/ri';
import moment from 'moment';
import {
  Button,
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
  IconButton,
  FormHelperText,
  Text,
  Select,
  Textarea,
  Radio, RadioGroup,Stack
} from '@chakra-ui/core';
import { createStaff, updateStaff, showStaff, getStaffList } from 'api/Church';
import { getmasstime,updatemasstime } from 'api/ChurchMassTime';
import context from 'context/dataProvider';

const userGetList = [
  'type_id',
  'languages_id',
  'week',
  'day',
  'start_time',
  'end_time',
  'notes',
];
const userGetList_TC = [
  '彌撒種類',
  '彌撒語系',
  '頻率',
  '星期',
  '彌撒開始時間',
  '彌撒結束時間',
  '彌撒備註',
];

const userGetListArray = [];

for (let itms = 0; itms <= userGetList_TC.length ; itms++) {
  userGetListArray.push({
    dataName: userGetList[itms],
    title: userGetList_TC[itms],
  });
}

const EditChurch = (props) => {
  const { setData , masstypedata , languagedata} = useContext(context);
  const targetID = props.targetID || null;
  const ChurchID = props.ChurchID || null;
  const { register, handleSubmit, errors } = useForm();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [formData, setFormData] = useState({
    name: null,
  });
  const [scrollBehavior, setScrollBehavior] = useState('inside');

  //console.log(moment(new Date()).format('YYYYMMDD'));

  const fetchList = async () => {
    const { data } = await getStaffList();

    setData(data.data);
  };

  const onSubmit = (values) => {
    if (targetID) {
      updatemasstime(targetID, values)
        .then(function (res) {
          if (res.status === 200) {
            toast({
              duration: 2000,
              position: 'bottom',
              render: (props) => (
                <Flex
                  background=" #00D086"
                  boxShadow=" 0px 4px 40px rgba(121, 58, 0, 0.2)"
                  borderRadius=" 10px"
                  w="270px"
                  h="150px"
                  maxW="95vw"
                  maxH="25vh"
                  alignItems="center"
                  justifyContent="center"
                  mb="1em"
                >
                  <Flex color="white">
                    <CgCheckO size="30px" />
                    <Box ml="14.5px" mr="12px" textAlign="left">
                      <Box
                        fontSize="30px"
                        mb="12px"
                        fontWeight="600"
                        lineHeight="30px"
                      >
                        資料更新完成
                      </Box>
                      <Text fontSize="22.5px">{values.name}</Text>
                    </Box>
                  </Flex>
                </Flex>
              ),
            });
            onClose();
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    if (isOpen && targetID) {
      async function fetchData() {
        const { data } = await getmasstime(targetID);
        setFormData(data.data);
      }

      fetchData();

    } else {
      setFormData({ name: null });
    }
  }, [isOpen, targetID]);

  return (
    <Flex display={targetID ? 'inline' : 'flex'}>
      {targetID ? (
        <IconButton onClick={onOpen} icon="edit" />
      ) : (
        <Button
          onClick={onOpen}
          ml="auto"
          mb={4}
          backgroundColor="brand.400"
          _hover={{ bg: 'brand.500' }}
          _active={{ bg: 'brand.500' }}
          color="white"
          variant="solid"
        >
          <Box as={RiUserAddLine} mr={2} />
          新增彌撒時間
        </Button>
      )}
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        scrollBehavior={targetID ? scrollBehavior : null}
      >
        <ModalOverlay />
        <ModalContent borderRadius="10px" height="auto">
          <ModalHeader>{targetID ? '修改' : '新增'}彌撒時間</ModalHeader>
          <ModalCloseButton />
          <ModalBody mb={3}>
            <form onSubmit={handleSubmit(onSubmit)}>
              {targetID
                ? userGetListArray.map((item, i) => {
                    switch (item.dataName) {
                      case 'week':
                        return (
                            <FormControl key={i}>
                              <FormLabel htmlFor={item.dataName} mb={1} mt={1}>
                                {item.title}
                              </FormLabel>
                              <RadioGroup name={item.dataName} isInline
                                          value={String(formData[item.dataName])}
                                          onChange={(e) => setFormData({ ...formData, 'week' : e.target.value })}
                              >
                                <Radio variantColor="brand" value="每週" ref={register({ required: true })}>
                                  每週
                                </Radio>
                                <Radio variantColor="brand" value="1" ref={register({ required: true })}>
                                  其他
                                </Radio>
                              </RadioGroup>
                              {errors.name && (
                                  <FormHelperText>請填入{item.title}</FormHelperText>
                              )}
                            </FormControl>
                        );
                      case 'day':
                        return (
                            <FormControl key={i}>
                              <FormLabel htmlFor={item.dataName} mb={1} mt={1}>
                                {item.title}
                              </FormLabel>
                              <Select placeholder="選擇區間" ref={register({ required: true })} name={item.dataName} id={item.dataName}
                                      value={formData[item.dataName]}
                                      onChange={(e) => setFormData({ ...formData, 'day' : e.target.value })}
                              >
                                <option value="0">星期日</option>
                                <option value="1">星期一</option>
                                <option value="2">星期二</option>
                                <option value="3">星期三</option>
                                <option value="4">星期四</option>
                                <option value="5">星期五</option>
                                <option value="6">星期六</option>
                              </Select>
                              {errors.name && (
                                  <FormHelperText>請填入{item.title}</FormHelperText>
                              )}
                            </FormControl>
                        );
                      case 'start_time':
                        return (
                            <FormControl key={i}>
                              <FormLabel htmlFor={item.dataName} mb={1} mt={1}>
                                {item.title}
                              </FormLabel>
                              <Input
                                  type="time"
                                  id={item.dataName}
                                  name={item.dataName}
                                  defaultValue={formData[item.dataName]}
                                  ref={register({ required: true })}
                              />
                              {errors.name && (
                                  <FormHelperText>請填入{item.title}</FormHelperText>
                              )}
                            </FormControl>
                        );
                      case 'end_time':
                        return (
                            <FormControl key={i}>
                              <FormLabel htmlFor={item.dataName} mb={1} mt={1}>
                                {item.title}
                              </FormLabel>
                              <Input
                                  type="time"
                                  id={item.dataName}
                                  name={item.dataName}
                                  defaultValue={formData[item.dataName]}
                                  ref={register({ required: true })}
                              />
                              {errors.name && (
                                  <FormHelperText>請填入{item.title}</FormHelperText>
                              )}
                            </FormControl>
                        );
                      case 'type_id':
                        return (
                            <FormControl key={i}>
                              <FormLabel htmlFor={item.dataName} mb={1} mt={1}>
                                {item.title}
                              </FormLabel>
                              <Select placeholder="選擇彌撒種類" ref={register({ required: true })} name={item.dataName} id={item.dataName}
                                      value={formData[item.dataName]}
                                      onChange={(e) => setFormData({ ...formData, 'type_id' : e.target.value })}
                              >
                                {masstypedata !== undefined ? masstypedata.map((value,index) => (
                                    <option key={index} value={value.id}>
                                      {value.name}
                                    </option>
                                )):null}
                              </Select>
                              {errors.name && (
                                  <FormHelperText>請填入{item.title}</FormHelperText>
                              )}
                            </FormControl>
                        );
                      case 'languages_id':
                        return (
                            <FormControl key={i}>
                              <FormLabel htmlFor={item.dataName} mb={1} mt={1}>
                                {item.title}
                              </FormLabel>
                              <Select placeholder="選擇彌撒語系" ref={register({ required: true })} name={item.dataName} id={item.dataName}
                                      value={formData[item.dataName]}
                                      onChange={(e) => setFormData({ ...formData, 'languages_id' : e.target.value })}
                              >
                                {languagedata !== undefined ? languagedata.map((value,index) => (
                                    <option key={index} value={value.id}>
                                      {value.name}
                                    </option>
                                )):null}
                              </Select>
                              {errors.name && (
                                  <FormHelperText>請填入{item.title}</FormHelperText>
                              )}
                            </FormControl>
                        );
                      case 'notes':
                        return (
                            <FormControl key={i}>
                              <FormLabel htmlFor={item.dataName} mb={1} mt={1}>
                                {item.title}
                              </FormLabel>
                              <Textarea placeholder="彌撒備註" defaultValue={formData[item.dataName]} name={item.dataName} ref={register({ required: true })}/>
                              {errors.name && (
                                  <FormHelperText>請填入{item.title}</FormHelperText>
                              )}
                            </FormControl>
                        );
                      default:
                        return (
                            <FormControl key={i}>
                              <Input
                                  type="hidden"
                                  id={"church_id"}
                                  name={"church_id"}
                                  ref={register}
                                  defaultValue={ChurchID}
                              />
                              {errors.name && (
                                  <FormHelperText>請填入{item.title}</FormHelperText>
                              )}
                            </FormControl>
                        );
                    }
                  })
                : null}

              <Flex mt={4} pb={2} justify="flex-end">
                <Button variant="ghost" onClick={onClose} mr={3}>
                  <Box as={IoMdClose} mr={2} />
                  取消
                </Button>
                <Button
                  backgroundColor="brand.400"
                  _hover={{ bg: 'brand.500' }}
                  _active={{ bg: 'brand.500' }}
                  color="white"
                  type="submit"
                >
                  <Box as={FaSave} mr={2} />
                  {targetID ? '修改' : '新增'}
                </Button>
              </Flex>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default EditChurch;

//moment(new Date()).format('YYYYMMDD')
