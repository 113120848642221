import Axios from 'axios';
//import { getToken } from 'utils/auth';

export const getStaffList = () => {
  const { REACT_APP_API_URL } = process.env;
  let config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': `application/json`,
    },
  };

  return Axios.get(REACT_APP_API_URL + '/users', config);
};

export const getSigninList = () => {
  const { REACT_APP_API_URL } = process.env;
  let config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': `application/json`,
    },
  };

  return Axios.get(REACT_APP_API_URL + '/signin', config);
};

export const showStaff = (id) => {
  const { REACT_APP_API_URL } = process.env;
  let config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': `application/json`,
    },
  };

  return Axios.get(REACT_APP_API_URL + '/users/' + id, config);
};

export const createStaff = (data) => {
  const { REACT_APP_API_URL } = process.env;
  let config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': `application/json`,
    },
  };

  return Axios.post(REACT_APP_API_URL + '/users', data, config);
};

export const updateStaff = (id, data) => {
  const { REACT_APP_API_URL } = process.env;
  let config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': `application/json`,
    },
  };

  //認payload裡的id
  data.id = id;

  return Axios.put(REACT_APP_API_URL + '/users', data, config);
};

export const deleteStaff = (id) => {
  const { REACT_APP_API_URL } = process.env;
  let config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': `application/json`,
    },
  };

  return Axios.delete(REACT_APP_API_URL + '/users/' + id, config);
};
