import React, { useEffect, useState } from 'react';
import Table from 'shared/Table/Table';
import { Provider } from 'context/dataProvider';
import { getMassList } from 'api/MassType';
import EditMassType from 'pages/MassType/EditMassType';

export const MassType = (props) => {
	const [data, setData] = useState([]);
	const [type] = useState('MassType');
	const [columns] = useState([
		{
			Header: '彌撒種類No',
			accessor: 'id',
		},
		{
			Header: '名稱',
			accessor: 'name',
		},
		{ Header: '操作', accessor: 'massfunction' },
	]);

	const fetchData = () => {
		getMassList()
			.then(function (res) {
				setData(res.data.data);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	useEffect(() => {
		fetchData();
	}, []);

	const context = {
		data,
		setData,
		columns,
		type,
	};

	return (
		<Provider value={context}>
			<EditMassType />
			<Table />
		</Provider>
	);
};
