import React, { useContext, useEffect, useState } from 'react';
import Table from 'shared/Table/Table';
import { Provider } from 'context/dataProvider';
import { useForm } from 'react-hook-form';
import churchdata from 'churchdata/HankWeiSecret.json';
import globalContext from 'context/globalContext';
import {
	Select,
	Box,
	SimpleGrid
} from '@chakra-ui/core';

export const ChurchPage = (props) => {
	const [data, setData] = useState([]);
	//教區and鐸區option
	const [churchoption1, setchurchoption1] = useState([]);
	const [churchoption2, setchurchoption2] = useState([]);
	//儲存使用者選擇數值
	const [churchoptiontext1, setchurchoptiontext1] = useState('');
	const [churchoptiontext2, setchurchoptiontext2] = useState('');
	const [type] = useState('Staff');
	const {selectedCardData, setSelectedCardData} = useContext(globalContext);

	const {register, handleSubmit, watch, errors} = useForm();

	const [columns] = useState([
		{
			Header: '教堂ID',
			accessor: 'id',
		},
		{
			Header: '教堂名稱',
			accessor: 'name',
		},
		{Header: '教區', accessor: 'place1'},
		{Header: '鐸區', accessor: 'place2'},
		{
			Header: '彌撒時間操作',
			accessor: 'function',
			disableSortBy: true,
		},
	]);

	const fetchData = () => {
		churchdata.map((value, index) => {
			value.deanery.map((alldeanery, keydeanery) => {
				alldeanery.church.map((church, keychurch) => {
					const church_detail = {
						id: church.id,
						name: church.name,
						place1: value.name,
						place2: alldeanery.name
					}
					setData(data => [...data, church_detail]);
				})
			})
		})
	};

	const changefetchData = () => {
		setData([])
		console.log(churchoptiontext1,churchoptiontext2)
		churchdata.map((value, index) => {
			if (churchoptiontext1 != '') {
				if (value.name == churchoptiontext1) {
					value.deanery.map((alldeanery, keydeanery) => {
						if (churchoptiontext2 != '') {
							if (alldeanery.name == churchoptiontext2) {
								alldeanery.church.map((church, keychurch) => {
									const church_detail = {
										id: church.id,
										name: church.name,
										place1: value.name,
										place2: alldeanery.name
									}
									setData(data => [...data, church_detail]);
								})
							}
						} else {
							alldeanery.church.map((church, keychurch) => {
								const church_detail = {
									id: church.id,
									name: church.name,
									place1: value.name,
									place2: alldeanery.name
								}
								setData(data => [...data, church_detail]);
							})
						}
					})
				}
			} else {
				value.deanery.map((alldeanery, keydeanery) => {
					if (churchoptiontext2 != '') {
						if (alldeanery.name == churchoptiontext2) {
							alldeanery.church.map((church, keychurch) => {
								const church_detail = {
									id: church.id,
									name: church.name,
									place1: value.name,
									place2: alldeanery.name
								}
								setData(data => [...data, church_detail]);
							})
						}
					} else {
						alldeanery.church.map((church, keychurch) => {
							const church_detail = {
								id: church.id,
								name: church.name,
								place1: value.name,
								place2: alldeanery.name
							}
							setData(data => [...data, church_detail]);
						})
					}
				})
			}
		})
	};

	const changeOption = () => {
		churchdata.map((value, index) => {
			setchurchoption1(churchoption1 => [...churchoption1, value.name]);
		})
	};
	const handleOnChange = ({target}) => {
		setchurchoption2([])
		setchurchoptiontext1(target.value)
		//在抓一次json出來
		churchdata.map((value, index) => {
			if (value.name == target.value) {
				value.deanery.map((alldeanery, keydeanery) => {
					setchurchoption2(churchoption2 => [...churchoption2, alldeanery.name]);
				})
			}
		})
		setchurchoptiontext2('')
	}

	const secondhandleOnChange = ({target}) => {
		setchurchoptiontext2(target.value)
	}

	useEffect(() => {
		changeOption();
		fetchData();
	}, []);

	useEffect(() => {
		changefetchData()
	}, [churchoptiontext1, churchoptiontext2]);

	const context = {
		data,
		setData,
		columns,
		type,
	};

	return (
		<Provider value={context}>
			<SimpleGrid columns={3} spacing={10} mb="20px">
				<Box><Select placeholder="選擇教區" onChange={handleOnChange}>
					{churchoption1.map((value, index) => (
						<option key={index} value={value}>
							{value}
						</option>
					))}
				</Select></Box>
				<Box><Select placeholder="選擇鐸區" onChange={secondhandleOnChange}>
					{churchoption2.map((value, index) => (
						<option key={index} value={value}>
							{value}
						</option>
					))}
				</Select></Box>
				<Box></Box>
			</SimpleGrid>
			<Table formRegister={register}/>
		</Provider>
	);
};
