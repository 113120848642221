import React from 'react';
import { Box } from '@chakra-ui/core';

import { MdAccountBox } from 'react-icons/md';
import { AiOutlineQrcode,AiFillSetting } from 'react-icons/ai';
import { IoIosQrScanner } from 'react-icons/io';
import { FaRegListAlt, FaThList, FaChartPie } from 'react-icons/fa';

const SidebarIcon = (props) => {
  const { Icon } = props;
  const IconFuction = (iconName) => {
    if (iconName === 'MdAccountBox') {
      return MdAccountBox;
    } else if (iconName === 'AiOutlineQrcode') {
      return AiOutlineQrcode;
    } else if (iconName === 'IoIosQrScanner') {
      return IoIosQrScanner;
    } else if (iconName === 'FaRegListAlt') {
      return FaRegListAlt;
    } else if (iconName === 'FaThList') {
      return FaThList;
    } else if (iconName === 'FaChartPie') {
      return FaChartPie;
    } else if (iconName === 'AiFillSetting') {
      return AiFillSetting;
    }
  };
  return <Box mr="12px" w="16px" as={IconFuction(Icon)}></Box>;
};

export default SidebarIcon;
