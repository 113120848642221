import React from 'react';
import { Box, Flex, IconButton } from '@chakra-ui/core';
import { SidebarToggleBtn } from 'layout/Sidebar/SidebarToggleBtn';
import { logout } from 'actions/user';
import { connect } from 'react-redux';

const Nav = (props) => {
  return (
    <Flex
      pos="sticky"
      top={0}
      borderRadius="30px"
      bg="brand.400"
      align="center"
      as="nav"
      justify="space-between"
      px={3}
      py={2}
      wrap="wrap"
      zIndex="1000"
      {...props}
    >
      <SidebarToggleBtn />
      <Box>
        <IconButton
          bg="white"
          ml={2}
          isRound
          aria-label="登出"
          icon="logout"
          onClick={() => {
            props.logout();
          }}
        />
      </Box>
    </Flex>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, { logout })(Nav);
