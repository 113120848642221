import React, { useContext, useState } from 'react';
import { IconButton } from '@chakra-ui/core';
import context from 'context/globalContext';
import styled, { keyframes } from 'styled-components';

const headShake = keyframes`
  0% {
    transform: translateX(0);
  }
  6.5% {
    transform: translateX(-6px) rotateY(-9deg);
  }
  18.5% {
    transform: translateX(5px) rotateY(7deg);
  }
  31.5% {
    transform: translateX(-3px) rotateY(-5deg);
  }
  43.5% {
    transform: translateX(2px) rotateY(3deg);
  }
  50% {
    transform: translateX(0);
  }
`;

const StyledIconButton = styled(IconButton)`
  animation: ${headShake} 1s linear;
`;

export const SidebarToggleBtn = () => {
  const { toggleSidebarOpen } = useContext(context);
  const [openState, setOpenState] = useState(false);

  const sidebarHandler = () => {
    toggleSidebarOpen((prevState) => !prevState);
  };

  const toggleArrowDirection = () => {
    setOpenState(!openState);
  };

  return (
    <StyledIconButton
      fontSize="30px"
      mr={2}
      isRound
      aria-label="開合選單"
      icon={!openState ? 'chevron-left' : 'chevron-right'}
      bg="white"
      onClick={() => {
        sidebarHandler();
        toggleArrowDirection();
      }}
    />
  );
};
