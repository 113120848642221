import React, { useEffect, useState } from 'react';
import { FaSave } from 'react-icons/fa';
import { Provider } from 'context/dataProvider';
import { getLanguageList } from 'api/Language';
import { getMassList } from 'api/MassType';
import { getchurchmasstime,createchurchmasstime } from 'api/ChurchMassTime';
import ChurchMassTimeForm from 'pages/ChurchPage/ChurchMassTimeForm';
import { RiAddLine } from 'react-icons/ri'
import {
	Box, Button, IconButton, Flex, Accordion,
	AccordionItem,
	AccordionPanel,
	AccordionHeader,
	AccordionIcon, useDisclosure, Heading, Select, Text, useToast,
} from "@chakra-ui/core";
import { Link, useParams , useHistory} from "react-router-dom";
import Axios from 'axios'
import { useForm, FormProvider, useFormContext } from "react-hook-form";

export const AddChurchMassTime = (props) => {
	let history = useHistory()
	const {isOpen, onOpen, onClose} = useDisclosure();
	const [data, setData] = useState([]);
	const [masstypedata, setmasstypedata] = useState([]);
	const [languagedata, setlanguagedata] = useState([]);
	const [formreloaddata, setformreloaddata] = useState(['do']);
	const toast = useToast();
	const {targetID} = useParams()
	const methods = useForm();
	const [type] = useState('AddChurchMassTime');
	const [columns] = useState([
		{
			Header: 'No',
			accessor: 'id',
		},
		{
			Header: '彌薩種類',
			accessor: 'type_id',
		},
		{
			Header: '彌薩時間',
			accessor: 'start_time',
		},
		{Header: '操作', accessor: 'masstimefunction'},
	]);

	const fetchData = () => {
		Axios.all([getMassList(), getLanguageList(), getchurchmasstime(targetID)])
			.then(Axios.spread((acct, languagedetail, churchmasstimedetail) => {
				// 請求都完成後
				if (acct.status === 200 && languagedetail.status === 200 && churchmasstimedetail.status === 200) {
					console.log(churchmasstimedetail)
					setData(churchmasstimedetail.data.data);
					setmasstypedata(acct.data.data);
					setlanguagedata(languagedetail.data.data);
				}
			}))
			.catch((error) => {
				console.error(error.response)
			});
	};

	useEffect(() => {
		fetchData();
	}, []);

	const context = {
		data,
		masstypedata,
		languagedata,
		setData,
		columns,
		type,
	};

	const onSubmit = (values) => {
		console.log(values.masstime)
		createchurchmasstime(values.masstime)
			.then(function (res) {
				if (res.status === 200) {
					toast({
						duration: 2000,
						position: 'bottom',
						render: (props) => (
							<Box
								fontSize="2em"
								p="0.5em 1.5em"
								color="white"
								background="#38a169"
								borderRadius="10px"
								m={5}
							>
								{values.name}
								<Text>彌撒時間更新完成</Text>
							</Box>
						),
					});
					history.push('/dashboard/churchlist/edit/'+targetID)
				}
			})
			.catch(function (error) {
				console.log(error.response);
			});
	}

	return (
		<FormProvider {...methods} >
		<Provider value={context}>
			<Flex bg="#ffffff" borderRadius={10} p={5} justifyContent="center">
				<Box w="100%" maxWidth="800px">
					<Flex>
						<Heading as="h2" size="xl" mt={5} mb={8}>
							新增彌撒時間
						</Heading>
						<Button
							onClick={() => {
								setformreloaddata(formreloaddata => [...formreloaddata, 'do'])
							}}
							ml="auto"
							mb={4}
							backgroundColor="brand.400"
							_hover={{bg: 'brand.500'}}
							_active={{bg: 'brand.500'}}
							color="rgba(121, 58, 0, 0.8)"
							variant="solid"
						>
							<Box as={RiAddLine} mr={2}/>
							新增彌撒時間表單
						</Button>
					</Flex>
					<form onSubmit={methods.handleSubmit(onSubmit)}>
						<Accordion defaultIndex={[0]} allowMultiple>
							{formreloaddata !== undefined ? formreloaddata.map((value, index) => (
								<AccordionItem key={index}>
									<AccordionHeader _expanded={{bg: "brand.300", color: "#1f0e00cc"}}>
										<Box flex="1" textAlign="left">
											{index + 1}.彌撒時間
										</Box>
										<AccordionIcon/>
									</AccordionHeader>
									<AccordionPanel pb={4}>
										<ChurchMassTimeForm number={index} targetID={targetID}/>
									</AccordionPanel>
								</AccordionItem>
							)) : null}
						</Accordion>
						<Flex mt={4} pb={2} justify="flex-end">
							<Button
								backgroundColor="brand.400"
								_hover={{bg: 'brand.500'}}
								_active={{bg: 'brand.500'}}
								color="rgba(121, 58, 0, 0.8)"
								type="submit"
							>
								<Box as={FaSave} mr={2}/>
								批次新增
							</Button>
						</Flex>
					</form>
				</Box>
			</Flex>
		</Provider>
		</FormProvider>
	);
};
