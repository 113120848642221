import React from 'react'
import styled from 'styled-components'
import { Box } from '@chakra-ui/core'

const LoadingScene = styled(Box)`
	align-items: center;
	display: flex;
	font-size: 2em;
	height: 100vh;
	justify-content: center;
	width: 100vw;
`

export default function Loading() {
	return <LoadingScene bg="white">Logging you in...</LoadingScene>
}
