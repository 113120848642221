import React from 'react';
import {
  AiOutlineDoubleLeft,
  AiOutlineLeft,
  AiOutlineDoubleRight,
  AiOutlineRight,
} from 'react-icons/ai';
import { IconButton, Flex, Box, Select, Input, Stack } from '@chakra-ui/core';

export const Pagination = ({
  gotoPage,
  previousPage,
  nextPage,
  canPreviousPage,
  canNextPage,
  pageCount,
  pageOptions,
  pageIndex,
  pageSize,
  setPageSize,
  width,
}) => {
  return (
    <Flex
      flex="1"
      alignItems="center"
      justify={width > 768 ? 'flex-end' : 'center'}
      wrap="wrap"
    >
      <Flex mb={3}>
        <Box>
          <IconButton
            mr={1}
            icon={AiOutlineDoubleLeft}
            size="sm"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          />
          <IconButton
            mr={1}
            icon={AiOutlineLeft}
            size="sm"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          />
          <IconButton
            mr={1}
            icon={AiOutlineRight}
            size="sm"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          />
          <IconButton
            mr={1}
            icon={AiOutlineDoubleRight}
            size="sm"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          />
        </Box>
        <Box mr={1}>
          {pageIndex + 1} / {pageOptions.length}
        </Box>
      </Flex>
      <Flex mb={3}>
        <Box mr={1}>
          <Input
            size="sm"
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: '100px' }}
          />
        </Box>
        <Box mr={1}>
          <Select
            size="sm"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </Select>
        </Box>
      </Flex>
    </Flex>
  );
};
