import React, { useContext } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { CgCheckO } from 'react-icons/cg';
import { RiErrorWarningFill } from 'react-icons/ri';
import {
  NotificationImportant,
  AccountBox,
  DateRange,
} from '@material-ui/icons';

import {
  Box,
  Flex,
  Image,
  Text,
  Stack,
  Heading,
  Button,
  FormHelperText,
  FormControl,
  useToast,
  PseudoBox,
} from '@chakra-ui/core';
import Axios from 'axios';
import { connect } from 'react-redux';
import context from 'context/globalContext';
import { login } from 'actions/user';

const AnimateLoginBg = styled(Box)`
  &:before {
    background: linear-gradient(300deg, #f3c429 50%, #fff1da 50.05%);
    content: '';
    display: block;
    height: 150%;
    position: fixed;
    width: 150%;
    z-index: -1;
  }
`;

const StyledInput = styled.input`
  margin-left: 13px;
  background: transparent;
  font-size: 20px;
  width: 100%;
  h: auto;
  color: rgba(121, 58, 0, 0.6);
  &:focus {
    &::placeholder {
      color: rgba(121, 58, 0, 0.3);
    }
  }

  ::placeholder {
    color: rgba(121, 58, 0, 0.6);
  }
  &:-webkit-autofill {
    -webkit-transition-delay: 9999s;
  }
`;

const LoginPage = (props) => {
  const { setUserType, setUserId, setUserToken } = useContext(context);
  const { register, handleSubmit, errors } = useForm();
  const { REACT_APP_API_URL } = process.env;
  const toast = useToast();

  const onSubmit = (values) => {
    // INFO:: values = form output
    Axios.post(REACT_APP_API_URL + '/login', {
      email: values.email,
      password: values.password,
    })
      .then(function (response) {
        console.log(response.data)
        if (response.status === 200) {
          localStorage.setItem('token', response.data.api_token);
          localStorage.setItem('uid', response.data.user_id);
          localStorage.setItem('userName', values.email);
          props.login({
            token: response.data.api_token,
            isAdmin: true,
          });
          setUserId((prevState) => response.data.user_id);
          setUserToken((prevState) => response.data.api_token);

          toast({
            duration: 2000,
            position: 'bottom',
            render: () => (
              <Flex
                background=" #00D086"
                boxShadow=" 0px 4px 40px rgba(121, 58, 0, 0.2)"
                borderRadius=" 10px"
                w="270px"
                h="150px"
                maxW="95vw"
                maxH="25vh"
                alignItems="center"
                justifyContent="center"
                mb="1em"
              >
                <Flex color="white">
                  <CgCheckO size="30px" />
                  <Box ml="14.5px" mr="12px" textAlign="left">
                    <Box
                      fontSize="30px"
                      mb="12px"
                      fontWeight="600"
                      lineHeight="30px"
                    >
                      登入成功
                    </Box>
                    <Text fontSize="22.5px">{values.email}</Text>
                  </Box>
                </Flex>
              </Flex>
            ),
          });
        }
      })
      .catch(function (error) {
        console.log(error)
        console.log(error.response)
        if(error.response.data.message==='user not exist'){
          toast({
            duration: 2000,
            position: 'bottom',
            render: () => (
                <Flex
                    background="#D80034"
                    boxShadow=" 0px 4px 40px rgba(121, 58, 0, 0.2)"
                    borderRadius=" 10px"
                    w="270px"
                    h="150px"
                    maxW="95vw"
                    maxH="25vh"
                    alignItems="center"
                    justifyContent="center"
                    mb="1em"
                >
                  <Flex color="white">
                    <RiErrorWarningFill size="30px"/>
                    <Box ml="14.5px" mr="12px" textAlign="left">
                      <Box
                          fontSize="30px"
                          mb="12px"
                          fontWeight="600"
                          lineHeight="30px"
                      >
                        查無此帳戶
                      </Box>
                    </Box>
                  </Flex>
                </Flex>
            ),
          });
        }
        else {
          toast({
            duration: 2000,
            position: 'bottom',
            render: () => (
                <Flex
                    background="#D80034"
                    boxShadow=" 0px 4px 40px rgba(121, 58, 0, 0.2)"
                    borderRadius=" 10px"
                    w="270px"
                    h="150px"
                    maxW="95vw"
                    maxH="25vh"
                    alignItems="center"
                    justifyContent="center"
                    mb="1em"
                >
                  <Flex color="white">
                    <RiErrorWarningFill size="30px"/>
                    <Box ml="14.5px" mr="12px" textAlign="left">
                      <Box
                          fontSize="30px"
                          mb="12px"
                          fontWeight="600"
                          lineHeight="30px"
                      >
                        網路連線異常
                      </Box>
                    </Box>
                  </Flex>
                </Flex>
            ),
          });
        }
      });
  };

  return (
    <AnimateLoginBg
      alignItems="center"
      d="flex"
      h="100vh"
      justifyContent="center"
      w="100vw"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          bg="white"
          w="340px"
          maxWidth="90vw"
          p="20px"
          borderRadius={4}
          shadow="5px 5px 50px rgba(0, 0, 0, .2)"
        >
          <Heading
            textAlign="center"
            d="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Text
              fontSize="1.5rem"
              color="#793A00"
              m="0.8em 0"
              fontWeight="600"
            >
              麥力後台管理系統登入
            </Text>
          </Heading>
          <Stack spacing={3}>
            <FormControl>
              <PseudoBox
                d="flex"
                alignItems="center"
                bg="#FFF1CC"
                p="18px"
                _focusWithin={{
                  borderBottom: '4px solid',
                  marginBottom: '-4px',
                  borderColor: '#f3c429',
                }}
              >
                <Box color="#f3c429">
                  <AccountBox />
                </Box>

                <StyledInput
                  placeholder="輸入信箱"
                  type="text"
                  name="email"
                  id="email"
                  aria-describedby="password"
                  ref={register({ required: true })}
                  fontSize="20px"
                  h="auto"
                />
              </PseudoBox>
              {errors.email && (
                <FormHelperText id="email-helper-text">必填</FormHelperText>
              )}
            </FormControl>
            <FormControl>
              <PseudoBox
                d="flex"
                alignItems="center"
                bg="#FFF1CC"
                p="18px"
                _focusWithin={{
                  borderBottom: '4px solid',
                  marginBottom: '-4px',
                  borderColor: '#f3c429',
                }}
              >
                <Box color="#f3c429">
                  <DateRange />
                </Box>

                <StyledInput
                  placeholder="輸入密碼"
                  name="password"
                  id="password"
                  type="password"
                  aria-describedby="email-helper-text"
                  ref={register({ required: true })}
                  fontSize="20px"
                  h="auto"
                />
              </PseudoBox>

              {errors.password && (
                <FormHelperText id="password">必填</FormHelperText>
              )}
            </FormControl>
            <Button
              type="submit"
              bg="brand.400"
              color="white"
              _hover={{ bg: 'brand.600' }}
              _active={{ bg: 'brand.600', shadow: '3px 3px 0 #439D55' }}
              fontSize="24px"
              borderRadius="10px"
              m="15px 0"
              padding="30px 0"
              lineHeight="0 !important"
            >
              登入
            </Button>
          </Stack>
        </Box>
      </form>
    </AnimateLoginBg>
  );
};

export default connect(null, { login })(LoginPage);
