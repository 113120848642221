import React, { Fragment, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Button, Box } from '@chakra-ui/core';
import styled from '@emotion/styled';
import context from 'context/globalContext';
import SidebarIcon from 'layout/Sidebar/SidebarIcon';

const CustomButton = styled(Button)`
  align-content: stretch;
  display: flex;
  justify-content: space-between;
`;

export const SidebarMenuItem = (props) => {
  const { item, active } = props;
  const { toggleSidebarOpen, windowSize, setActiveItem } = useContext(context);

  return (
    <Fragment>
      {active === 'active' ? (
        <CustomButton
          _hover={{ bg: 'rgba(248, 119, 0, 0.2)' }}
          _active={{ bg: 'rgba(248, 119, 0, 0.2)' }}
          _focus={{ border: 'none' }}
          borderRadius="0px 18px 18px 0px"
          borderWidth="0"
          height="auto"
          p="0"
          backgroundColor="rgba(248, 119, 0, 0.2)"
          color="brand.400"
          width="96%"
          onClick={() => {
            setActiveItem(item.title_en);
            if (windowSize.width < 768 && item.title_en !== 'chartBanned') {
              toggleSidebarOpen(false);
            }
          }}
        >
          <Box
            as={Link}
            to={item.router}
            p="0.625em 1em"
            d="flex"
            alignItems="center"
            textAlign="left"
            width="100%"
          >
            <SidebarIcon Icon={item.icon} />
            {item.title}
          </Box>
        </CustomButton>
      ) : (
        <CustomButton
          _hover={{ bg: 'white' }}
          _active={{ bg: 'white' }}
          _focus={{ border: 'none' }}
          backgroundColor="white"
          borderRadius="0"
          borderWidth="0"
          height="auto"
          p="0"
          width="100%"
          onClick={() => {
            setActiveItem(item.title_en);
            if (windowSize.width < 768 && item.title_en !== 'chartBanned') {
              toggleSidebarOpen(false);
            }
          }}
        >
          <Box
            as={Link}
            to={item.router}
            p="0.625em 1em"
            d="flex"
            alignItems="center"
            textAlign="left"
            width="100%"
          >
            <SidebarIcon Icon={item.icon} />
            {item.title}
          </Box>
        </CustomButton>
      )}
    </Fragment>
  );
};
