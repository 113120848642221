import Axios from 'axios';
import { getToken } from 'utils/auth'

export const getMassList = () => {
	const { REACT_APP_API_URL } = process.env;
	let config = {
		headers: {
			Authorization: `Bearer ${getToken()}`,
			'Content-Type': `application/json`,
		},
	};

	return Axios.get(REACT_APP_API_URL + '/types', config);
};

export const getonlyMassList = (id) => {
	const { REACT_APP_API_URL } = process.env;
	let config = {
		headers: {
			Authorization: `Bearer ${getToken()}`,
			'Content-Type': `application/json`,
		},
	};

	return Axios.get(REACT_APP_API_URL + '/types/' + id, config);
};

export const insertonlyMassList = (data) => {
	const { REACT_APP_API_URL } = process.env;
	let config = {
		headers: {
			Authorization: `Bearer ${getToken()}`,
			'Content-Type': `application/json`,
		},
	};

	return Axios.post(REACT_APP_API_URL + '/types', data, config);
};

export const updateonlyMassList = (id, data) => {
	const { REACT_APP_API_URL } = process.env;
	let config = {
		headers: {
			Authorization: `Bearer ${getToken()}`,
			'Content-Type': `application/json`,
		},
	};

	return Axios.put(REACT_APP_API_URL + '/types/' + id, data, config);
};

export const deleteMassType = (id, data) => {
	const { REACT_APP_API_URL } = process.env;
	let config = {
		headers: {
			Authorization: `Bearer ${getToken()}`,
			'Content-Type': `application/json`,
		},
	};

	return Axios.delete(REACT_APP_API_URL + '/types/' + id, config);
};