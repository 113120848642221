import React, { useEffect, useState } from 'react';
import Table from 'shared/Table/Table';
import { Provider } from 'context/dataProvider';
import { getLanguageList } from 'api/Language';
import EditLanguage from 'pages/LanguagePage/EditLanguage';

export const LanguagePage = (props) => {
	const [data, setData] = useState([]);
	const [type] = useState('Language');
	const [columns] = useState([
		{
			Header: '語系種類No',
			accessor: 'id',
		},
		{
			Header: '名稱',
			accessor: 'name',
		},
		{ Header: '操作', accessor: 'languagefunction' },
	]);

	const fetchData = () => {
		getLanguageList()
			.then(function (res) {
				console.log(res.data.data)
				setData(res.data.data);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	useEffect(() => {
		fetchData();
	}, []);

	const context = {
		data,
		setData,
		columns,
		type,
	};

	return (
		<Provider value={context}>
			<EditLanguage />
			<Table />
		</Provider>
	);
};
